import { ComputedRef, Ref, computed, onBeforeUnmount, ref } from 'vue'
import { getTrainCirculation } from '@/api/graph/getTrainCirculation'
import { addHours, format, startOfHour, subHours } from 'date-fns'
import { useGraph } from './useGraph'

import Dialog from 'quasar/src/plugins/Dialog.js';

import GraphFordDefectModal from '@/components/graph/GraphFordDefectModal.vue'

export interface GraphCirculationTrip {
  departure_date: string
  name: string
  place_end: string
  place_start: string
  time_end: string
  time_start: string
  train_id: string
}

interface TripItem {
  train_id: string
  departure_date: string
  selected: boolean
  boundryBox: {
    x1: number
    y1: number
    x2: number
    y2: number
  }
}

interface UseGraphCirculation {
  setSelectedTrainNumber: (v: string | null, departureDate: string) => void
  init: (canvas: HTMLCanvasElement) => void
  timeline: ComputedRef<{ hour: string; date: Date | null }[]>
  canvasHeight: Ref<number>
  loading: Ref<boolean>
  circulations: Ref<{ trips: TripItem[]; name: string }[]>
}

const loading = ref(false)
const selectedTrainNumber = ref<string | null>(null)
let ctx: CanvasRenderingContext2D | null = null
let scrollContainer: HTMLDivElement | null = null
const dpi = window.devicePixelRatio || 1
const SIDEBAR_LEFT_WIDTH = 100
const TIMELINE_HEIGHT = 16

const circulations = ref<{ trips: TripItem[]; name: string }[]>([])
const startTime = ref(new Date())
const canvasHeight = ref(0)

const setCanvasSize = () => {
  if (!ctx || !ctx.canvas.parentElement) return
  const { width } = ctx.canvas.parentElement.getBoundingClientRect()
  ctx.canvas.width = width * dpi
  ctx.canvas.height = canvasHeight.value * dpi
  ctx.canvas.style.width = `${width}px`
  ctx.canvas.style.height = `${canvasHeight.value}px`
  ctx.scale(dpi, dpi)
}

const onResize = () => setCanvasSize()

export const useGraphCirculation = (): UseGraphCirculation => {
  const scrollLeft = ref<number>(0)
  const scrollTop = ref<number>(0)

  const getXWithScroll = (x: number) => x - scrollLeft.value

  const calcXPosition = (time: string) => {
    return ~~((+new Date(time) - +startTime.value) / 60000) + SIDEBAR_LEFT_WIDTH
  }

  const parseTrip = (trip: GraphCirculationTrip, i: number): TripItem => {
    const train_id = trip.train_id.toString()
    return {
      train_id,
      departure_date: trip.departure_date,
      selected: selectedTrainNumber.value === train_id,
      boundryBox: {
        x1: calcXPosition(trip.time_start),
        y1: i * 40,
        x2: calcXPosition(trip.time_end),
        y2: i * 40 + 40,
      },
    }
  }

  const setSelectedTrainNumber: UseGraphCirculation['setSelectedTrainNumber'] =
    async (value, departureDate) => {
      selectedTrainNumber.value = value

      if (!circulations.value.length) {
        circulations.value = []
        canvasHeight.value = 50
        if (ctx?.canvas.parentElement) {
          ctx.canvas.parentElement.style.height = `${canvasHeight.value}px`
        }
        setCanvasSize()
      }

      if (value) {
        loading.value = true
        const params = {
          trainId: value,
          departureDate,
        }

        const { data: circulation_data } = await getTrainCirculation(params)
        loading.value = false
        const selected = circulation_data.find((x) => x.train_id === value)

        if (selected) {
          startTime.value = startOfHour(
            subHours(new Date(selected.time_start), 12)
          )

          const data = Object.entries(
            circulation_data.reduce<{ [key: string]: TripItem[] }>(
              (acc, item) => {
                if (!acc[item.name]) {
                  acc[item.name] = []
                }

                const index = Object.keys(acc).findIndex((x) => x === item.name)

                acc[item.name] = circulation_data
                  .filter((x) => x.name === item.name)
                  .map((x) => parseTrip(x, index))

                return acc
              },
              {}
            )
          ).map(([name, trips]) => ({
            name,
            trips,
          }))

          circulations.value = data

          if (ctx?.canvas.parentElement) {
            const height = data.length * 40 + TIMELINE_HEIGHT + 20
            ctx.canvas.parentElement.style.height = `${height}px`
            canvasHeight.value = height
            setCanvasSize()
          }

          const selectedTripItem = data
            .flatMap((x) => x.trips)
            .find((x) => x.train_id === value)
          if (selectedTripItem && ctx?.canvas.parentElement) {
            const { width } = ctx.canvas.parentElement.getBoundingClientRect()
            ctx?.canvas.parentElement.scrollTo(
              selectedTripItem.boundryBox.x1 - width / 2,
              0
            )
          }
        }

        if (!circulation_data.length) {
          circulations.value = []
          canvasHeight.value = 50
          if (ctx?.canvas.parentElement) {
            ctx.canvas.parentElement.style.height = `${canvasHeight.value}px`
          }
          setCanvasSize()
        }
      }
    }

  const render = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.textAlign = 'center'
    ctx.font = '10px Arial'

    //timeline day lines
    for (let i = 0; i < timeline.value.length; i++) {
      const hour = timeline.value[i]
      const x = getXWithScroll(SIDEBAR_LEFT_WIDTH + i * 60)
      if (hour.date) {
        ctx.fillStyle = '#ccc'
        ctx.fillRect(x, TIMELINE_HEIGHT, 1, ctx.canvas.height)
      }
    }

    for (let y = 0; y < circulations.value.length; y++) {
      const circulation = circulations.value[y]
      const yPos = TIMELINE_HEIGHT

      for (const trip of circulation.trips) {
        ctx.fillStyle = trip.selected ? 'blue' : 'black'

        const width = trip.boundryBox.x2 - trip.boundryBox.x1
        const x = getXWithScroll(trip.boundryBox.x1)
        ctx.fillText(
          trip.train_id.toString(),
          x + width / 2,
          trip.boundryBox.y1 + yPos + 8 + 16
        )

        ctx.fillRect(x, trip.boundryBox.y1 + 28 + yPos, width, 3)
      }
    }

    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, SIDEBAR_LEFT_WIDTH, ctx.canvas.height)

    ctx.textAlign = 'left'
    ctx.font = 'bold 12px Arial'
    for (let y = 0; y < circulations.value.length; y++) {
      const circulation = circulations.value[y]
      const yPos = y * 40 + TIMELINE_HEIGHT
      ctx.fillStyle = '#000'
      ctx.fillText(circulation.name, 8, yPos + 18)

      ctx.fillStyle = '#ccc'
      ctx.fillRect(0, yPos + 40, ctx.canvas.width, 1)
    }

    ctx.fillStyle = '#ccc'
    ctx.fillRect(SIDEBAR_LEFT_WIDTH, 0, 1, ctx.canvas.height)

    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, ctx.canvas.width, TIMELINE_HEIGHT)

    ctx.fillStyle = '#ccc'
    ctx.fillRect(0, TIMELINE_HEIGHT, ctx.canvas.width, 1)

    //timeline
    ctx.textAlign = 'left'
    for (let i = 0; i < timeline.value.length; i++) {
      ctx.font = 'normal 10px Arial'
      const hour = timeline.value[i]
      const x = getXWithScroll(SIDEBAR_LEFT_WIDTH + i * 60)

      ctx.fillStyle = '#000'
      ctx.fillText(hour.hour, x + 2, TIMELINE_HEIGHT - 2)

      // if (hour.date) {
      //   ctx.font = 'bold 14px Arial'
      //   ctx.fillText(format(hour.date, 'yyyy-MM-dd'), x + 6, 24)
      // }

      ctx.fillStyle = '#ccc'
      ctx.fillRect(
        x,
        hour.date ? 0 : TIMELINE_HEIGHT - 12,
        1,
        hour.date ? TIMELINE_HEIGHT : 12
      )
    }
  }

  const draw = () => {
    if (ctx) render(ctx)

    window.requestAnimationFrame(draw)
  }

  const canvasWidth = 1440 + SIDEBAR_LEFT_WIDTH

  const onMouseDown = (e: MouseEvent) => {
    if (!ctx || !ctx.canvas.parentElement) return

    const { model } = useGraph()

    const { top, left } = ctx.canvas.parentElement.getBoundingClientRect()

    const x = e.clientX - left
    const y = e.clientY - top

    const { height, width } = ctx?.canvas.getBoundingClientRect()
    if (x >= width - 20 || y / dpi >= height - 20) return
    const trips = circulations.value.flatMap((x) => x.trips)

    for (let i = 0; i < circulations.value.length; i++) {
      const circulation = circulations.value[i]
      const y1 = i * 40 + TIMELINE_HEIGHT
      const y2 = i * 40 + 40 + TIMELINE_HEIGHT

      if (0 <= x && x <= SIDEBAR_LEFT_WIDTH && y1 <= y && y <= y2) {
        Dialog.create({
          component: GraphFordDefectModal,
          componentProps: {
            data: {
              name: circulation.name,
              date: model.value.departureDate,
            },
          },
        })
        break
      }
    }

    for (const trip of trips) {
      if (
        getXWithScroll(trip.boundryBox.x1) <= x &&
        x <= getXWithScroll(trip.boundryBox.x2) &&
        trip.boundryBox.y1 + TIMELINE_HEIGHT <= y &&
        y <= trip.boundryBox.y2 + TIMELINE_HEIGHT
      ) {
        model.value.trainNumber = trip.train_id
        model.value.departureDate = trip.departure_date
        break
      }
    }
  }

  onBeforeUnmount(() => {
    if (!ctx) return
    ctx.canvas.removeEventListener('mousedown', onMouseDown)
  })

  const timeline = computed(() => {
    const hours = new Array(24).fill(null).map((x, i) => {
      const date = addHours(startTime.value, i)
      return {
        hour: format(date, 'HH'),
        date: i === 0 || date.getHours() === 0 ? date : null,
      }
    })

    return hours
  })

  const init: UseGraphCirculation['init'] = (canvas) => {
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    ctx.canvas.style.display = 'block'
    ctx.canvas.style.position = 'sticky'
    ctx.canvas.style.top = '0'
    ctx.canvas.style.left = '0'

    if (canvas.parentElement) {
      canvas.parentElement.style.position = 'relative'
      canvas.parentElement.style.overflowY = 'hidden'
      canvas.parentElement.style.overflowX = 'auto'
      canvas.parentElement.classList.add('ganttScroll')

      scrollContainer = document.createElement('div')
      scrollContainer.style.position = 'absolute'
      scrollContainer.style.left = '0'
      scrollContainer.style.top = '0'

      scrollContainer.style.width = `${canvasWidth}px`
      scrollContainer.style.height = `1px`

      canvas.parentElement.appendChild(scrollContainer)
      canvas.parentElement.addEventListener('scroll', (e) => {
        const { scrollLeft: sl, scrollTop: st } = e.target as HTMLCanvasElement
        scrollLeft.value = sl
        scrollTop.value = st
      })
    }

    setCanvasSize()

    const onResizeFn = () => onResize()
    window.addEventListener('resize', onResizeFn)
    ctx.canvas.addEventListener('mousedown', onMouseDown)
    window.requestAnimationFrame(draw)

    return null
  }

  return {
    setSelectedTrainNumber,
    init,
    timeline,
    canvasHeight,
    loading,
    circulations,
  }
}
