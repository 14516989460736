import { render } from "./DeviationDelayMPKMultipleModal.vue?vue&type=template&id=90efa0a2"
import script from "./DeviationDelayMPKMultipleModal.vue?vue&type=script&lang=ts"
export * from "./DeviationDelayMPKMultipleModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QCardActions,QBtn});
