
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject, ref } from 'vue'
import { MpkEventMessageData } from '@/api/graph/getMpkEventMessage'

export default defineComponent({
  name: 'DeviationDelayMPKModal',

  setup() {
    const modal =
      inject<UseModal<{ mpkMessageData: MpkEventMessageData[] }>>('opal-modal')!
    const { mpkMessageData } = modal.state.data!
    console.log(mpkMessageData)

    const activeTab = ref(0)

    const headers = [
      {
        label: 'Rubrik',
        key: 'Header',
      },
      {
        label: 'Händelsebeskrivning (intern)',
        key: 'InternalText',
      },
      {
        label: 'Händelsebeskrivning (extern)',
        key: 'ExternalDescription',
      },
    ]

    return { modal, mpkMessageData, headers, activeTab }
  },
})
